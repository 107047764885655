import { twMerge } from "tailwind-merge";

export type ModalColor = "default" | "orange" | "blue" | "cyan";

export const uiModal = (color?: ModalColor) => {
  let title = "";

  switch (color) {
    case "orange":
      title = "text-orange-500";
      break;
    case "blue":
      title = "text-blue-500";
      break;
    case "cyan":
      title = "text-cyan-500";
      break;
    default:
      title = "text-(--ui-text-highlighted)";
  }

  return {
    header: "flex items-center justify-between",
    title: twMerge("font-semibold leading-6 flex items-center gap-2", title),
    footerActions: "flex justify-end items-end gap-3",
  };
};
