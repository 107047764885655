<script setup lang="ts">
import { IconName } from "~/utils/enum/icon_name";
import type { NuxtUiCardUi } from "~/types/nuxt/ui/card";
import type { NuxtUiModalUi } from "~/types/nuxt/ui/modal";
import { type ModalColor, uiModal } from "~/components/modal/ui";

const props = withDefaults(
  defineProps<{
    title?: string;
    color?: ModalColor;
    icon?: IconName;
    hideFooter?: boolean;
    cardUi?: NuxtUiCardUi;
    ui?: NuxtUiModalUi;
  }>(),
  {
    title: undefined,
    color: "default",
    icon: undefined,
    hideFooter: false,
    cardUi: () => ({}),
    ui: () => ({}),
  },
);

const emits = defineEmits<{
  (e: "close"): void;
}>();

const model = defineModel<boolean>();

function hideModal() {
  emits("close");
  model.value = false;
}

const ui = computed(() => uiModal(props.color));
</script>

<template>
  <UModal
    v-model:open="model"
    :ui="{ ...ui }"
    :title="props.title"
    :description="props.title"
  >
    <template #content>
      <UCard
        class="h-auto overflow-auto"
        :ui="{
          ...cardUi,
        }"
      >
        <template #header>
          <div :class="ui.header">
            <h3 :class="ui.title">
              <UIcon v-if="props.icon" :name="props.icon" size="20" />
              {{ props.title || "" }}
            </h3>
            <UButton
              color="neutral"
              variant="ghost"
              :icon="IconName.XMark"
              class="-my-1"
              @click="hideModal"
            />
          </div>
        </template>

        <template #default>
          <slot name="default" />
        </template>

        <template v-if="!props.hideFooter" #footer>
          <div :class="ui.footerActions">
            <slot name="footer" />

            <template v-if="!$slots.footer">
              <UButton
                label="Закрыть"
                variant="ghost"
                color="info"
                @click="hideModal"
              />
            </template>

            <slot name="footer-actions" />
          </div>

          <slot name="footer-sub-actions" />
        </template>
      </UCard>
    </template>
  </UModal>
</template>

<style scoped></style>
